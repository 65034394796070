import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  ICreateMicrosoftTenant,
  IMicrosoftTenant,
  IMicrosoftTenantStatus,
  IUpdateMicrosoftBillingProfile,
  MicrosoftTenantFilters
} from '../../models/microsoft-tenant.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { buildQueryParamsByObject } from '../../../shared/functions/util-functions';
import { IPagedRequest, IPagedResponse } from '../../../shared/models/paged-result.model';
import { ICustomer } from 'src/modules/customer/models/customer.model';
import { CountryValidationRules } from '../../models/country-validation-rules';
import {IPartner} from '../../../partner/models/partner.model';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftTenantService {
  private readonly microsoftAPI = environment.microsoftV2API + '/';
  private readonly microsoftTenantAPI = this.microsoftAPI + 'tenants';

  constructor(private http: HttpClient) {}

  getMicrosoftTenant(id: IMicrosoftTenant['id']): Observable<IMicrosoftTenant> {
    return this.http.get<IMicrosoftTenant>(this.microsoftTenantAPI + '/' + id);
  }

  getMicrosoftTenants(pagination: IPagedRequest<MicrosoftTenantFilters>): Observable<IPagedResponse<IMicrosoftTenant>> {
    const params = buildQueryParamsByObject(pagination);
    return this.http.get<IPagedResponse<IMicrosoftTenant>>(this.microsoftTenantAPI, { params: params });
  }

  getCustomerAttachedMicrosoftTenant(id: ICustomer['id']): Observable<IMicrosoftTenant> {
    return this.http.get<IMicrosoftTenant>(this.microsoftAPI + 'customer/' + id);
  }

  getMicrosoftTenantStatus(customerId: ICustomer['id'], partnerId: IPartner['id']): Observable<IMicrosoftTenantStatus[]> {
    return this.http.get<IMicrosoftTenantStatus[]>(this.microsoftAPI + 'customer/' + customerId + '/status?partnerId=' + partnerId);
  }

  createMicrosoftTenant(payload: ICreateMicrosoftTenant): Observable<IMicrosoftTenant> {
    return this.http.post<IMicrosoftTenant>(this.microsoftTenantAPI, payload);
  }

  validateMicrosoftTenant(payload: ICreateMicrosoftTenant): Observable<boolean> {
    return this.http.post<boolean>(this.microsoftTenantAPI + '/validate', payload);
  }

  updateMicrosoftTenant(tenantId: IMicrosoftTenant['id'], payload: IUpdateMicrosoftBillingProfile): Observable<IMicrosoftTenant> {
    return this.http.put<IMicrosoftTenant>(this.microsoftTenantAPI + '/' + tenantId + '/billingprofile', payload);
  }

  checkDomainAvailable(domainPrefix: { domain: string }): Observable<boolean> {
    return this.http.post<boolean>(this.microsoftAPI + 'utility/domain/available', domainPrefix);
  }

  getValidationRules(countryCode: string): Observable<CountryValidationRules> {
    return this.http.get<CountryValidationRules>(`${this.microsoftAPI}utility/country/${countryCode}/validation-rules`);
  }

  retryApplicationConsent(customerId: string) {
    return this.http.post<boolean>(`${this.microsoftAPI}customer/${customerId}/ApplicationConsent`, {});
  }
}
